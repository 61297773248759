import { useState, useEffect, useRef } from "react"; // Include useRef
import axios from 'axios';
import ProgressBar from 'react-bootstrap/ProgressBar';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css'; // Include your custom CSS

function App() {
  const [imageBlob, setImageBlob] = useState(null);
  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const isGeneratingImage = useRef(false);
  const [jobId, setJobId] = useState(null); // Add state to store Job ID
  const [apiStatus, setApiStatus] = useState(null);  // Add this line near your other useState declarations
  const myAPIKey = process.env.REACT_APP_MY_API_KEY;
  const [jobIds, setJobIds] = useState([]); // Changed to an array to store multiple job IDs
  const [generatedImages, setGeneratedImages] = useState([]); // Store generated image URLs
  // State for current generation images and gallery images
  const [currentImages, setCurrentImages] = useState([]);




  const [galleryImages, setGalleryImages] = useState([]);
  const [jobIndexMap, setJobIndexMap] = useState({});
  const placeholderImage = `${process.env.PUBLIC_URL}/placeholder.png`; // Update with actual placeholder image path
  const [newJobIndexMap, setNewJobIndexMap] = useState({});
  const [loraSelection, setLoraSelection] = useState(" "); // Default Lora

  const [backendType, setBackendType] = useState('comfy'); // 'comfy' for ComfyUI
  const toggleBackendType = () => {
    setBackendType(backendType === 'comfy' ? 'comfy' : 'automatic');
  };

  const [useRandomSeed, setUseRandomSeed] = useState(true);

  const [uploadedFiles, setUploadedFiles] = useState([]);

  const [presetOptions, setPresetOptions] = useState([]);
  const [selectedPreset, setSelectedPreset] = useState('');

  const apiURL = process.env.REACT_APP_API_URL || 'http://192.168.1.123:5000';

  const [progressPercentage, setProgressPercentage] = useState(0);
  const progressIntervalRef = useRef(null);


  const gifPreset = {
    "1": {
      "inputs": {
        "ckpt_name": "deliberate_v2.safetensors",
        "beta_schedule": "sqrt_linear (AnimateDiff)"
      },
      "class_type": "CheckpointLoaderSimpleWithNoiseSelect"
    },
    "6": {
      "inputs": {
        "text": "{negative prompt}",
        "clip": [
          "1",
          1
        ]
      },
      "class_type": "CLIPTextEncode"
    },
    "7": {
      "inputs": {
        "seed": 425394211730499,
        "steps": 20,
        "cfg": 6,
        "sampler_name": "dpmpp_2m_sde_gpu",
        "scheduler": "sgm_uniform",
        "denoise": 1,
        "model": [
          "115",
          0
        ],
        "positive": [
          "104",
          0
        ],
        "negative": [
          "6",
          0
        ],
        "latent_image": [
          "113",
          0
        ]
      },
      "class_type": "KSampler"
    },
    "10": {
      "inputs": {
        "samples": [
          "7",
          0
        ],
        "vae": [
          "1",
          2
        ]
      },
      "class_type": "VAEDecode"
    },
    "93": {
      "inputs": {
        "model_name": "v3_sd15_mm.ckpt",
        "beta_schedule": "sqrt_linear (AnimateDiff)",
        "motion_scale": 1,
        "apply_v2_models_properly": false,
        "model": [
          "1",
          0
        ],
        "context_options": [
          "94",
          0
        ]
      },
      "class_type": "ADE_AnimateDiffLoaderWithContext"
    },
    "94": {
      "inputs": {
        "context_length": 16,
        "context_stride": 1,
        "context_overlap": 4,
        "context_schedule": "uniform",
        "closed_loop": false
      },
      "class_type": "ADE_AnimateDiffUniformContextOptions"
    },
    "102": {
      "inputs": {
        "frame_rate": 12,
        "loop_count": 0,
        "filename_prefix": "AnimateDiff",
        "format": "image/gif",
        "pingpong": false,
        "save_output": true,
        "crf": 20,
        "save_metadata": true,
        "audio_file": "",
        "videopreview": {
          "hidden": false,
          "paused": false,
          "params": {
            "filename": "AnimateDiff_00073.gif",
            "subfolder": "",
            "type": "output",
            "format": "image/gif"
          }
        },
        "images": [
          "10",
          0
        ]
      },
      "class_type": "VHS_VideoCombine"
    },
    "104": {
      "inputs": {
        "text": "{prompt}",
        "clip": [
          "1",
          1
        ]
      },
      "class_type": "CLIPTextEncode"
    },
    "113": {
      "inputs": {
        "width": 512,
        "height": 512,
        "batch_size": 48
      },
      "class_type": "EmptyLatentImage"
    },
    "115": {
      "inputs": {
        "lora_name": "v3_sd15_adapter.ckpt",
        "strength_model": 1,
        "strength_clip": 1,
        "model": [
          "93",
          0
        ],
        "clip": [
          "1",
          1
        ]
      },
      "class_type": "LoraLoader"
    }
  };

  const [comfySettings, setComfySettings] = useState(gifPreset);

  const presetModelMappings = {
    "Anime": "cardosAnime_v20.safetensors",
    "Realistic 1": "epicrealism_naturalSinRC1VAE.safetensors",
    "Realistic 2": "deliberate_v2.safetensors",
  };

  const initialImageState = {
    url: placeholderImage,
    status: 'pending', // 'pending', 'in_progress', 'completed'
    progress: 0, // Progress percentage
  };







  const createImageMetadata = (url, prompt, traits, lora) => ({
    url,
    prompt,
  });

  const handleFileSelect = (event) => {
    setUploadedFiles(event.target.files); // Assuming you have a state to store the selected files
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };


  const handleSaveSettings = () => {
    let settingsData;
    if (backendType === 'automatic') {
      settingsData = {
        prompt: customPrompt,
        negativePrompt: customNegativePrompt,
        settingsJson: settingsJson
      };
    } else { // For ComfyUI
      settingsData = {
        prompt: customPrompt,
        negativePrompt: customNegativePrompt,
        comfySettings: comfySettings,
        selectedModel: selectedModel // Save the selected model
      };
    }

    const blob = new Blob([JSON.stringify(settingsData, null, 2)], { type: 'application/json' });
    const href = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = href;
    link.download = backendType === 'automatic' ? "settings-automatic.json" : "settings-comfy.json";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };



  const processSettingsData = (settingsData) => {
    try {
      if (settingsData.settingsJson) {
        // Handle Automatic1111 settings
        setCustomPrompt(settingsData.prompt);
        setCustomNegativePrompt(settingsData.negativePrompt);
        setSettingsJson(settingsData.settingsJson);
        setBackendType('automatic');
      } else if (settingsData.comfySettings) {
        // Handle ComfyUI settings
        setCustomPrompt(settingsData.prompt);
        setCustomNegativePrompt(settingsData.negativePrompt);
        setComfySettings(settingsData.comfySettings);
        setBackendType('comfy');
        setSelectedModel(settingsData.selectedModel);
      } else {
        console.error("Unknown settings format");
      }
    } catch (error) {
      console.error("Error processing settings data:", error);
    }
  };

  const handleLoadSettings = (event) => {
    const fileReader = new FileReader();
    fileReader.onload = (fileLoadedEvent) => {
      const textFromFileLoaded = fileLoadedEvent.target.result;
      processSettingsData(JSON.parse(textFromFileLoaded));
    };

    fileReader.readAsText(event.target.files[0]);
  };

  const handlePresetChange = (event) => {
    const selectedPresetName = event.target.value;
    setSelectedPreset(selectedPresetName);

    if (selectedPresetName) {
      const selectedModelCheckpoint = presetModelMappings[selectedPresetName];
      if (selectedModelCheckpoint) {
        // Create a deep copy of the gifPreset to modify
        let updatedGifPreset = JSON.parse(JSON.stringify(gifPreset));
        updatedGifPreset["1"].inputs.ckpt_name = selectedModelCheckpoint;

        // Update the Comfy settings with the new preset
        setComfySettings(updatedGifPreset);
      }
    }
  };






  // Usage of the input file element
  <input type="file" id="fileInput" className="form-control" onChange={handleLoadSettings} />

  const updateImageFilenameInSettings = (settings, filename) => {
    for (const key in settings) {
      const node = settings[key];
      if (node.class_type === "LoadImage" && node.inputs && node.inputs.image) {
        node.inputs.image = filename;
      }
    }
  }


  const replacePlaceholders = (obj, prompt, negativePrompt) => {
    Object.keys(obj).forEach(key => {
      if (typeof obj[key] === 'string') {
        obj[key] = obj[key].replace(/{prompt}/g, prompt).replace(/{negative prompt}/g, negativePrompt);
      } else if (typeof obj[key] === 'object') {
        replacePlaceholders(obj[key], prompt, negativePrompt);
      }
    });
  };

  const replaceRandomTag = (obj) => {
    Object.keys(obj).forEach(key => {
      if (typeof obj[key] === 'string') {
        obj[key] = obj[key].replace(/{random}/g, () => Math.floor(Math.random() * 1000000));
      } else if (typeof obj[key] === 'object') {
        replaceRandomTag(obj[key]);
      }
    });
  };

  const replaceModelTag = (obj, selectedModel) => {
    Object.keys(obj).forEach(key => {
      if (typeof obj[key] === 'string') {
        obj[key] = obj[key].replace(/{model}/g, selectedModel);
      } else if (typeof obj[key] === 'object' && obj[key] !== null) {
        replaceModelTag(obj[key], selectedModel);
      }
    });
  };


  const parseJSONGracefully = (jsonString, defaultValue = {}) => {
    try {
      return JSON.parse(jsonString);
    } catch (error) {
      console.error("Failed to parse JSON:", error);
      return defaultValue; // or return an error message/object indicating the issue
    }
  };

  const replaceSeedValuesWithRandom = (obj) => {
    Object.keys(obj).forEach(key => {
      if (key === 'seed' && typeof obj[key] === 'number') {
        obj[key] = Math.floor(Math.random() * 1000000000); // Random seed
      } else if (typeof obj[key] === 'object' && obj[key] !== null) {
        replaceSeedValuesWithRandom(obj[key]); // Recurse into nested objects
      }
    });
  };

  const [selectedModel, setSelectedModel] = useState("realvisxlV30_v30Bakedvae"); // Default model
  const [modelOptions, setModelOptions] = useState([]);

  const fetchModels = async () => {
    try {
      const payload = {
        input: {
          api: {
            method: "GET",
            endpoint: "/sdapi/v1/sd-models"
          },
          payload: {}
        }
      };

      const response = await axios.post('https://api.runpod.ai/v2/4633x1fjhlagrj/runsync', payload, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${myAPIKey}`// Make sure myAPIKey is defined
        }
      });

      if (response.data && response.data.output) {
        setModelOptions(response.data.output.map(model => model.model_name));
      }
    } catch (error) {
      console.error('Error fetching models:', error);
    }
  };

  useEffect(() => {
    fetchModels(); // Fetch models on component mount
  }, []);



  useEffect(() => {
    const fetchPresets = async () => {
      try {
        const response = await axios.get(`${apiURL}/api/presets`);
        if (response.data && response.data.presets) {
          setPresetOptions(response.data.presets);
        }
      } catch (error) {
        console.error('Error fetching presets:', error);
      }
    };

    fetchPresets();
  }, []);



  const [numberOfImages, setNumberOfImages] = useState(1);

  const [customPrompt, setCustomPrompt] = useState('A person smiling at the camera. the coolest GIF that the internet has seen. Extremely beautiful.');
  const [customNegativePrompt, setCustomNegativePrompt] = useState('(worst quality, low quality, deformed face, deformed eyes, nude)');

  const [settingsJson, setSettingsJson] = useState(`{
    "override_settings": {
      "sd_model_checkpoint": "realvisxlV30_v30Bakedvae"
    },
    "override_settings_restore_afterwards": true,
    "seed": -1,
    "batch_size": 1,
    "steps": 30,
    "cfg_scale": 7,
    "hr_scale": 2,
    "enable_hr": false,
    "hr_second_pass_steps": 20,
    "hr_upscaler": "Latent",
    "denoising_strength": 0.4,
    "width": 1536,
    "height": 1024,
    "sampler_name": "DPM++ 2M SDE Karras",
    "sampler_index": "DPM++ 2M SDE Karras",
    "restore_faces": false
  }`);


  function base64ToBlob(base64, mimeType) {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: mimeType });
  }

  useEffect(() => {
    // When the component mounts, you can set additional presets if required
    // For now, it will just use the default preset defined above
    setComfySettings(gifPreset);
  }, []);

  const jobTimers = useRef({});


  // CHECK JOB FUNCTION ************************

  const checkJobStatus = async (job) => {
    try {
      let jobCompleted = false;

      const endpoint = job.backendType === 'automatic'
        ? `https://api.runpod.ai/v2/4633x1fjhlagrj/status/${job.id}`
        : `https://api.runpod.ai/v2/6cfddahqrk2f57/status/${job.id}`;

      const response = await axios.get(endpoint, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${myAPIKey}`
        },
      });

      console.log(`Received status update for job ID ${job.id}: `, response.data);

      if (response.data.status === 'IN_PROGRESS' && !jobTimers.current[job.id]) {
        // Start a new timer only if we don't have one for this job ID

        console.log(`Starting progress for job ID: ${response.data.id}`);

        jobTimers.current[job.id] = setInterval(() => {
          setCurrentImages((currentImages) => currentImages.map((img, idx) => {
            if (idx === job.index) {
              let newProgress = img.progress + (100 / 135);
              if (newProgress >= 100) {
                clearInterval(jobTimers.current[job.id]);
                delete jobTimers.current[job.id];
                newProgress = 100;
              }
              return { ...img, progress: newProgress, status: 'in_progress' };
            }
            return img;
          }));
        }, 1000);
      }

      // Clear timers when the job is no longer in progress
      if (['COMPLETED', 'FAILED'].includes(response.data.status)) {
        setCurrentImages(currentImages => {
          return currentImages.map((image, idx) => {
            if (image.timer && idx === job.index) {
              clearInterval(image.timer);
              return { ...image, status: 'completed', progress: 100, timer: null };
            }
            return image;
          });
        });
      }


      // Check if the job is completed
      if (response.data && response.data.status === 'COMPLETED') {
        jobCompleted = true;
      }

      // Update the API status
      if (response.data && response.data.status) {
        setApiStatus(response.data.status);
      }

      let imageBlob;

      // If the job is completed, process the image based on the backend type
      if (jobCompleted) {
        if (job.backendType === 'comfy' && response.data.output && response.data.output.message) {
          const imageBase64 = response.data.output.message[0];
          imageBlob = base64ToBlob(imageBase64, 'image/png'); // Convert base64 to Blob
          console.log("Image from ComfyUI:", imageBlob);
        } else if (job.backendType === 'automatic' && response.data.output && response.data.output.images) {
          const imageBytes = atob(response.data.output.images[0]);
          const bytes = new Uint8Array(imageBytes.length);
          for (let i = 0; i < imageBytes.length; i++) {
            bytes[i] = imageBytes.charCodeAt(i);
          }
          imageBlob = new Blob([bytes.buffer], { type: 'image/png' });
          console.log("Image metadata:", response.data.output.metadata);
        }

        // Inside checkJobStatus function, right before updating the UI
        console.log('newJobIndexMap:', newJobIndexMap);
        console.log(`Checking job status for ID: ${job.id}`);

        // Update the UI with the new image blob
        if (imageBlob && newJobIndexMap[job.id]) {
          const imageInfo = newJobIndexMap[job.id];
          console.log('Updating image for job ID:', job.id, 'Image info:', imageInfo, 'Image Blob:', imageBlob);

          setCurrentImages(prevImages => {
            const updatedImages = [...prevImages];
            updatedImages[imageInfo.index] = {
              url: URL.createObjectURL(imageBlob),
              metadata: imageInfo.metadata ? imageInfo.metadata : { /* default or placeholder metadata */ }
            };
            console.log('Updated images:', updatedImages);
            return updatedImages;
          });
        } else {
          console.log(`Completed job ID ${job.id} but no image or metadata found.`);
        }

        // Instead of removing the job ID here, mark it for removal
        return job.id; // Return the completed job ID
      }
      // Return null for ongoing jobs
      return null;
    } catch (err) {
      console.error(err);
      return null; // Return null in case of error
    }
  };




  // GENERATE ART FUNCTION ************************

  const generateArt = async () => {
    setIsLoading(true);
    isGeneratingImage.current = true;

    // First, move current images to the gallery
    setGalleryImages(prevGallery => [...prevGallery, ...currentImages.map(image => ({ ...image }))]);
    setCurrentImages([]); // Optionally reset current images

    // Reset placeholders with initial status and progress
    const placeholders = new Array(numberOfImages).fill().map((_, idx) => ({
      url: placeholderImage,
      metadata: null,
      progress: 0,
      status: 'waiting', // Start with 'waiting' status
    }));

    setCurrentImages(placeholders);

    // Function to simulate progress for a single image
    const simulateProgress = (index) => {
      const updateInterval = 1000; // Update every second
      const totalDuration = 145000; // Total duration in milliseconds
      const incrementPerUpdate = (100 / totalDuration) * updateInterval;

      const intervalId = setInterval(() => {
        setCurrentImages((currentImages) => {
          const newImages = [...currentImages];
          const image = newImages[index];

          if (image.status === 'in_progress') {
            image.progress += incrementPerUpdate;
            if (image.progress >= 100) {
              image.progress = 100; // Cap at 100%
              clearInterval(intervalId); // Stop simulation
            }
          }

          return newImages;
        });
      }, updateInterval);

      // Save the interval ID for potential cleanup
      return intervalId;
    };

    // Start progress simulation for each image
    const simulationIds = placeholders.map((_, index) => simulateProgress(index));


    // Initialize arrays for new job IDs and mapping of job IDs to image indices
    const newJobIds = [];
    const newJobIndexMap = {}; // Initialize as an empty object

    const tempNewJobIndexMap = {}; // Temporary map to build up new data


    let payload, response; // Declare here for broader scope


    for (let i = 0; i < numberOfImages; i++) {

      const negativePromptText = customNegativePrompt || "(clone, siamese twins, worst quality, low quality, illustration, 3d, 2d, painting, cartoons, sketch, deformed)";

      let settings = {};
      try {
        settings = JSON.parse(settingsJson);
      } catch (error) {
        console.error("Failed to parse settings JSON:", error);
        return;
      }

      const promptText = customPrompt
      console.log("Generated Prompt:", promptText);

      const modelUsed = settings.override_settings?.sd_model_checkpoint || "Default Model";
      const samplerUsed = settings.sampler_name || "Default Sampler";

      const metadata = {
        prompt: promptText,
        model: modelUsed, // Add model to metadata
        sampler: samplerUsed // Add sampler to metadata
      };

      //AUTOMATIC1111
      try {
        if (backendType === 'automatic') {
          console.log("Sending request to API");
          payload = {
            "input": {
              "api": {
                "method": "POST",
                "endpoint": "/sdapi/v1/txt2img"
              },
              "payload": {
                ...settings,
                "prompt": promptText,
                "negative_prompt": negativePromptText
              }
            }
          };

          response = await axios.post(
            `https://api.runpod.ai/v2/4633x1fjhlagrj/run`,
            payload,
            {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${myAPIKey}`
              },
            }
          );
          console.log("Received response from API", response.data);


          //COMFYUI

        } else {
          console.log("Sending request to ComfyUI API", comfySettings);
          let comfyPayload = { input: { workflow: JSON.parse(JSON.stringify(comfySettings)) } };
          replacePlaceholders(comfyPayload.input.workflow, customPrompt, customNegativePrompt);
          if (useRandomSeed) {
            replaceSeedValuesWithRandom(comfyPayload.input.workflow); // Replace seed values if random seed is enabled
          }
          replaceModelTag(comfyPayload.input.workflow, selectedModel);

          // Add uploaded files to payload if any
          if (uploadedFiles && uploadedFiles.length > 0) {
            const firstFileName = uploadedFiles[0].name; // Assuming we use the first file's name for all LoadImage nodes
            updateImageFilenameInSettings(comfyPayload.input.workflow, firstFileName);

            const imagesForUpload = [];
            for (const file of uploadedFiles) {
              const base64 = await convertToBase64(file);
              imagesForUpload.push({
                name: file.name,
                image: base64.split(',')[1] // Remove base64 prefix
              });
            }
            comfyPayload.input.images = imagesForUpload; // Add images to payload
          }

          payload = comfyPayload;

          response = await axios.post(`https://api.runpod.ai/v2/6cfddahqrk2f57/run`, comfyPayload, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${myAPIKey}`
            },
          });
          console.log("Received response from ComfyUI API", response.data);


          // Handle response for ComfyUI
          // Process the response and update your application state as needed 
        }

        if (response.data && response.data.id) {
          newJobIds.push({ id: response.data.id, backendType: backendType });
          newJobIndexMap[response.data.id] = { index: i, metadata: metadata };


        }
      } catch (err) {
        console.error(err);
      } finally {
        //setJobIds(newJobIds); // Update the jobIds state with new job IDs
        //setNewJobIndexMap(prevMap => ({ ...prevMap, ...tempNewJobIndexMap }));
        console.log("newJobIndexMap after generation:", newJobIndexMap);
        setIsLoading(false);
        isGeneratingImage.current = false;
      }
    }
    setJobIds(prevJobIds => [...prevJobIds, ...newJobIds]);

    // Update newJobIndexMap
    setNewJobIndexMap(prevMap => ({ ...prevMap, ...newJobIndexMap }));
  }



  useEffect(() => {
    const interval = setInterval(async () => {
      const completedJobIds = await Promise.all(
        jobIds.map(async (job) => await checkJobStatus(job))
      );

      // Filter out nulls and update the jobIds state to remove completed jobs
      const idsToRemove = completedJobIds.filter(id => id !== null);
      if (idsToRemove.length > 0) {
        setJobIds(prevJobIds => prevJobIds.filter(job => !idsToRemove.includes(job.id)));
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [jobIds]); // Depend on jobIds





  // ACTUAL PAGE ************************

  return (
    <div className="container my-5">
      <h1 className="text-center display-4 mb-4">GIF Generator</h1>
      <div className="d-flex flex-column align-items-center">
        {/* Flex container for Generate button and Number of Images Selector */}
        <div className="d-flex align-items-center mb-4">
          <button onClick={generateArt} className="btn btn-dark btn-lg me-2">Generate</button>
          <select
            value={numberOfImages}
            onChange={(e) => setNumberOfImages(Number(e.target.value))}
            className="form-select form-select-lg"  // Removed mb-3 to align with the button
          >
            {[...Array(4).keys()].map((number) => (
              <option key={number} value={number + 1}>
                {number + 1}
              </option>
            ))}
          </select>
        </div>

        <div className="row mb-3">
          {currentImages.map((imageObject, index) => (
            <div className="col-md-6 col-12 mb-2" key={index}>
              <div className="image-container position-relative">
                <img src={imageObject.url} alt={`Current Art ${index + 1}`} className="generated-image w-100" />

                {imageObject.status === 'in_progress' && (
                  <div className="progress-overlay">
                    <div className="progress-bar-container">
                      <div className="progress-bar" style={{ width: `${imageObject.progress}%` }}>
                        <span className="progress-text">{`${imageObject.progress.toFixed(0)}%`}</span>
                      </div>

                    </div>
                  </div>
                )}

              </div>
            </div>
          ))}
        </div>

        {isLoading && apiStatus && (
          <div className="status-text-container">
            <p>{`Status: ${apiStatus}`}</p>
          </div>
        )}
        <div className="w-75">
          <h2 className="text-center mb-3">Settings</h2>




          <form className="mb-3">
            <div className="form-group">
              <textarea
                className="form-control"
                placeholder="Enter custom prompt"
                value={customPrompt}
                onChange={(e) => setCustomPrompt(e.target.value)}
                rows="5"
              >

              </textarea>
            </div>
            <div className="form-group">
              <textarea
                className="form-control"
                placeholder="Enter custom negative prompt"
                value={customNegativePrompt}
                onChange={(e) => setCustomNegativePrompt(e.target.value)}
                rows="3"
              ></textarea>
            </div>

            {/* Automatic1111 */}
            {/* Settings Field */}
            {backendType === 'automatic' ? (
              // Settings for Automatic1111
              <div className="form-group">

              </div>
            ) : (

              <div className="form -group">




              </div>
            )}

            <div className="mb-3">
              <label htmlFor="presetSelect" className="form-label">Select a preset</label>
              <select
                id="presetSelect"
                className="form-select mb-2"
                value={selectedPreset}
                onChange={handlePresetChange}
              >
                <option value="">Select a preset</option>
                <option value="Anime">Anime - Cardos</option>
                <option value="Realistic 1">Realistic 1 - EpicRealism</option>
                <option value="Realistic 2">Realistic 2 - Deliberate</option>
              </select>
            </div>



          </form>
        </div>




        <h2 className="text-center display-6 mb-3">Gallery</h2>
        <div className="row mb-3">
          {galleryImages.map((imageObject, index) => (
            <div className="col-md-6 col-12 mb-2" key={index}>
              <div className="image-container position-relative">
                <img src={imageObject.url} alt={`gallery Art ${index + 1}`} className="generated-image w-100" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );

}

export default App;
